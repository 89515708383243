<template>
  <div class="competition">
    <div class="content">
      <div class="head">
        <h3>{{ dataInfo.competitionName }}</h3>
        <h5>{{ dataInfo.showSbuName == 1 ? dataInfo.subName : "" }}</h5>
        <div class="steps">
          <div
            class="step step-r"
            :class="[
              { active: stepStatus >= 1 },
              { 'active-r': stepStatus >= 1 },
            ]"
          >
            <div class="spot"><i></i></div>
            <div class="text">
              <div class="name">{{ $t("cm_start") }}</div>
              <div class="time">
                {{ dateFormat(dataInfo.collectStartTime) }}
              </div>
            </div>
          </div>
          <div
            class="step step-l step-r"
            :class="[
              { active: stepStatus >= 1 },
              { 'active-l': stepStatus >= 1 },
              { 'active-r': stepStatus >= 2 },
            ]"
          >
            <div class="step-name">{{ $t("SolicitationPeriod") }}</div>
          </div>
          <div
            class="step step-l step-r"
            :class="[
              { active: stepStatus >= 2 },
              { 'active-l': stepStatus >= 2 },
              { 'active-r': stepStatus >= 3 },
            ]"
          >
            <div class="spot"><i></i></div>
            <div class="text">
              <div class="name">{{ $t("ContestDeadline") }}</div>
              <div class="time">
                {{ dateFormat(dataInfo.collectEndTime) }}
              </div>
            </div>
          </div>
          <div
            class="step step-l step-r"
            :class="[
              { active: stepStatus >= 3 },
              { 'active-l': stepStatus >= 3 },
              { 'active-r': stepStatus == 5 },
            ]"
          >
            <div class="step-name">
              {{ $t("ScoringPeriod") }}
            </div>
          </div>
          <div
            class="step step-l"
            :class="[
              { active: stepStatus == 5 },
              { 'active-l': stepStatus == 5 },
            ]"
          >
            <div class="spot"><i></i></div>
            <div class="text">
              <div class="name">{{ $t("AnnouncementPeriod") }}</div>
              <div class="time">{{ dateFormat(dataInfo.rankStartTime) }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="tabs">
        <a-tabs v-model:activeKey="tabActive" @change="tabChange">
          <template #tabBarExtraContent>
            <a-button
              type="primary"
              @click="jump(0)"
              v-if="
                stepStatus == 1 &&
                (dataInfo.entryLimitCount == -1 ||
                  dataInfo.entryLimitCount > worksList.length)
              "
            >
              {{ $t("Contest") }}
            </a-button>
          </template>
          <a-tab-pane key="rule" :tab="$t('ActivityRules')">
            <div class="competition-detail">
              <h3>{{ dataInfo.competitionName }}</h3>
              <p v-if="dataInfo.showSbuName == 1">{{ dataInfo.subName }}</p>
              <p>
                <strong>{{ $t("SolicitationPeriod") }}：</strong
                >{{ dateFormat(dataInfo.collectStartTime) }} ~
                {{ dateFormat(dataInfo.collectEndTime) }}
              </p>
              <p>
                <strong>{{ $t("ScoringPeriod") }}：</strong
                >{{ dateFormat(dataInfo.scoringStartTime) }} ~
                {{ dateFormat(dataInfo.scoringEndTime) }}
              </p>
              <p>
                <strong>{{ $t("AnnouncementPeriod") }}：</strong
                >{{ dateFormat(dataInfo.rankStartTime) }}
              </p>
              <p>
                <strong>{{ $t("ModifyingWorks") }}：</strong
                >{{
                  dataInfo.isEdit == 1
                    ? $t("ModifyingWorksOpt1")
                    : $t("ModifyingWorksOpt2")
                }}
              </p>
              <p>
                <strong>{{ $t("NumberOfEntries") }}：</strong
                >{{
                  dataInfo.entryLimitCount == -1
                    ? $t("Unrestricted")
                    : dataInfo.entryLimitCount + $t("PubTimes")
                }}
              </p>
              <p>
                <strong>{{ $t("CompetitionRules") }}：</strong
                >{{ dataInfo.competitionRule }}
              </p>
            </div>
          </a-tab-pane>
          <a-tab-pane
            key="list"
            :tab="
              stepStatus == 5
                ? $t('XB_RankingList')
                : `${$t('Entries')}（${contestPageTotal}${$t('LB_My_Ge')}）`
            "
          >
            <div id="pageTop2"></div>
            <div class="contest-list">
              <a-spin :spinning="contestLoading">
                <template v-if="contestList.length">
                  <div
                    class="card"
                    v-for="item in contestList"
                    :key="item.entryId"
                  >
                    <div class="ranking-num" v-if="stepStatus == 5">
                      <div class="number" v-if="item.rankNum == 1">
                        <img
                          src="../../assets/image/competition_rank_1.png"
                          alt=""
                        />
                      </div>
                      <div class="number" v-else-if="item.rankNum == 2">
                        <img
                          src="../../assets/image/competition_rank_2.png"
                          alt=""
                        />
                      </div>
                      <div class="number" v-else-if="item.rankNum == 3">
                        <img
                          src="../../assets/image/competition_rank_3.png"
                          alt=""
                        />
                      </div>
                      <div class="number" v-else>{{ item.rankNum }}</div>
                      <div class="score">
                        {{ item.score }}{{ $t("Lab_Teacher_Fen") }}
                      </div>
                    </div>
                    <div class="cover">
                      <img :src="item.coverImg" alt="cover" />
                    </div>
                    <div
                      class="detail"
                      :style="{
                        width:
                          stepStatus == 5
                            ? 'calc(100% - 382px)'
                            : 'calc(100% - 282px)',
                      }"
                    >
                      <div class="text">
                        <h5>
                          {{ item.entryName }}
                        </h5>
                        <p class="intro">
                          {{ item.intro }}
                        </p>
                        <div class="bottom">
                          <div class="user">
                            <div class="avatar">
                              <img :src="item.portrait" alt="avatar" />
                            </div>
                            <span>{{ item.createUser }}</span>
                          </div>
                          <div class="time">
                            {{ $t("Pub_Lab_UploadTime") }}：
                            {{ dateFormat(item.createTime) }}
                          </div>
                        </div>
                      </div>
                      <div class="btns">
                        <div
                          class="btn"
                          @click="
                            $refs.worksModalRef.handleOpen(
                              item,
                              dataInfo.questionnaireId
                            )
                          "
                        >
                          {{ $t("view_detail") }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="page-Wrap">
                    <a-pagination
                      show-quick-jumper
                      :show-total="
                        (total) => $t('XB_InTotal', [contestPageTotal])
                      "
                      :defaultPageSize="15"
                      v-model:current="contestCurrentPage"
                      :total="contestPageTotal"
                      @change="contestPageChange"
                    >
                      <template #itemRender="{ type, originalElement }">
                        <a class="page-a" v-if="type === 'prev'">{{
                          $t("cm_pre")
                        }}</a>
                        <a class="page-a" v-else-if="type === 'next'">{{
                          $t("cm_next")
                        }}</a>
                        <renderVNode
                          v-else
                          :vnode="originalElement"
                        ></renderVNode>
                      </template>
                    </a-pagination>
                  </div>
                </template>
                <a-empty
                  v-else
                  :image="require('@/assets/image/no_data_3.png')"
                  :image-style="{ height: '186px' }"
                  style="margin-top: 150px"
                >
                  <template #description>
                    <span
                      style="
                        color: #999;
                        margin-top: 6px;
                        display: inline-block;
                      "
                      >{{ $t("NoEntriesYet") }}</span
                    >
                  </template>
                </a-empty>
              </a-spin>
            </div>
          </a-tab-pane>
          <a-tab-pane
            key="mine"
            :tab="`${$t('MyWork')}（${worksList.length}${$t('LB_My_Ge')}）`"
            v-if="stepStatus != 5"
          >
            <div class="works-list">
              <a-spin :spinning="worksLoading">
                <template v-if="worksList.length">
                  <div
                    class="card"
                    v-for="item in worksList"
                    :key="item.entryId"
                  >
                    <div
                      class="cover"
                      @click="
                        $refs.worksModalRef.handleOpen(
                          item,
                          dataInfo.questionnaireId
                        )
                      "
                    >
                      <img :src="item.coverImg" alt="cover" />
                      <template v-if="dataInfo.isAudit == 2">
                        <div class="tag green" v-if="item.status == 1">
                          {{ $t("Pub_Passed") }}
                        </div>
                        <div class="tag blue" v-else-if="item.status == 6">
                          {{ $t("Pub_Lab_PendingAudit") }}
                        </div>
                        <div class="tag red" v-else-if="item.status == 7">
                          {{ $t("teacher.rejected") }}
                        </div>
                      </template>
                    </div>
                    <div class="detail">
                      <div
                        class="text"
                        :style="{ width: stepStatus == 1 ? '690px' : '100%' }"
                        @click="
                          $refs.worksModalRef.handleOpen(
                            item,
                            dataInfo.questionnaireId
                          )
                        "
                      >
                        <h5>
                          {{ item.entryName }}
                        </h5>
                        <p class="score">
                          {{ $t("LB_Favorite_Score") }}：
                          <span v-if="stepStatus >= 4">
                            {{ item.score }}
                          </span>
                          <template v-else>-</template>
                        </p>
                        <div class="bottom">
                          <div class="user">
                            <div class="avatar">
                              <img :src="item.portrait" alt="avatar" />
                            </div>
                            <span>{{ item.createUser }}</span>
                          </div>
                          <div class="time">
                            {{ $t("Pub_Lab_UploadTime") }}：
                            {{ dateFormat(item.createTime) }}
                          </div>
                        </div>
                      </div>
                      <div class="btns" v-if="stepStatus == 1">
                        <div
                          class="btn"
                          @click="jump(item.entryId)"
                          v-if="dataInfo.isAudit == 1"
                        >
                          {{ $t("cm_modify") }}
                        </div>
                        <div
                          class="btn"
                          @click="jump(item.entryId)"
                          v-else-if="dataInfo.isEdit == 1 && item.status != 1"
                        >
                          {{ $t("cm_modify") }}
                        </div>
                        <a-popconfirm
                          :title="$t('bbs.sure_delete')"
                          @confirm="del(item.entryId)"
                        >
                          <div class="btn red">
                            {{ $t("CM_Delete") }}
                          </div>
                        </a-popconfirm>
                      </div>
                    </div>
                  </div>
                </template>
                <a-empty
                  v-else
                  :image="require('@/assets/image/no_data_3.png')"
                  :image-style="{ height: '186px' }"
                  style="margin-top: 150px"
                >
                  <template #description>
                    <span
                      style="
                        color: #999;
                        margin-top: 6px;
                        display: inline-block;
                      "
                      >{{ $t("LookingForwardToYourWork") }}</span
                    >
                  </template>
                </a-empty>
              </a-spin>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <worksModal ref="worksModalRef" />
  </div>
</template>

<script>
import { reactive, toRefs, watch, getCurrentInstance } from "vue";
import { useRoute, useRouter } from "vue-router";
import { dateFormat } from "@/utils/tools";
import {
  competitionDetail,
  competitionEntryList,
  competitionEntryDel,
} from "@/api/competition";
import worksModal from "./worksModal.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    worksModal,
    renderVNode,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const currentTime = Date.parse(new Date()) / 1000;
    const state = reactive({
      competitionId: parseInt(route.query.competitionId || 0),
      tabActive: "rule",
      dataInfo: {},
      contestList: [],
      contestLoading: false,
      contestCurrentPage: 1,
      contestPageTotal: 0,
      worksList: [],
      worksLoading: false,
      stepStatus: 0,
    });

    watch(
      () => route.hash,
      (val) => {
        if (val) state.tabActive = val.slice(1);
      },
      { immediate: true }
    );

    const tabChange = (e) => {
      // location.hash = e;
    };

    competitionDetail(state.competitionId).then((res) => {
      if (res.ret === 0) {
        let d = res.data;
        state.dataInfo = d;
        state.worksList = d.myEntryList || [];
        if (currentTime < d.collectStartTime) {
          state.stepStatus = 0; // 未开始
        } else if (
          currentTime >= d.collectStartTime &&
          currentTime < d.collectEndTime
        ) {
          state.stepStatus = 1; // 征集期
        } else if (
          currentTime >= d.collectEndTime &&
          currentTime < d.scoringStartTime
        ) {
          state.stepStatus = 2; // 参赛截止
        } else if (
          currentTime >= d.scoringStartTime &&
          currentTime < d.scoringEndTime
        ) {
          state.stepStatus = 3; // 评分期
        } else if (
          currentTime >= d.scoringEndTime &&
          currentTime < d.rankStartTime
        ) {
          state.stepStatus = 4; // 评分截止
        } else if (currentTime >= d.rankStartTime) {
          state.stepStatus = 5; // 揭榜期
          state.tabActive = 'list';
        }
      } else {
        router.replace({
          path: `/error`,
          query: {
            title: res.msg,
            path: "/competition",
            name: proxy.$t("MicroCourseCompetition"),
          },
        });
      }
    });

    const jump = (entryId) => {
      router.push({
        path: "/competition/contest",
        query: {
          competitionId: state.competitionId,
          entryId: entryId,
        },
      });
    };

    const getContestList = (scroll = false) => {
      state.contestLoading = true;
      competitionEntryList({
        page: state.contestCurrentPage,
        pageSize: 15,
        searchType: 1,
        competitionId: state.competitionId,
      }).then((res) => {
        if (res.ret === 0) {
          state.contestLoading = false;
          state.contestPageTotal = res.data.totals;
          state.contestList = res.data.list || [];
          if (scroll) {
            setTimeout(() => {
              document.getElementById("pageTop2").scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 0);
          }
        }
      });
    };
    getContestList();

    const contestPageChange = (n) => {
      state.contestCurrentPage = n;
      getContestList(true);
    };

    const getWorksList = () => {
      state.worksLoading = true;
      competitionDetail(state.competitionId).then((res) => {
        state.worksLoading = false;
        state.worksList = res.data.myEntryList || [];
      });
    };

    const del = (id) => {
      competitionEntryDel({
        competitionId: state.competitionId,
        entryId: id,
      }).then(() => {
        proxy.$message.success(proxy.$t("CM_SuccessfullyDelete"));
        getWorksList();
        state.contestCurrentPage = 1;
        getContestList(true);
      });
    };

    return {
      dateFormat,
      ...toRefs(state),
      tabChange,
      jump,
      contestPageChange,
      del,
    };
  },
};
</script>

<style lang="less" scoped>
.competition {
  background-color: #fafafa;
  padding: 24px 0 58px;
  .content {
    .mixinWrap();
    .head {
      background-image: url("../../assets/image/competition-detail-head.png");
      height: 201px;
      border-radius: 8px;
      padding: 16px;
      h3 {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        color: #fff;
        text-align: center;
        margin-bottom: 4px;
      }
      h5 {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        height: 24px;
        color: #fff;
        text-align: center;
        margin-bottom: 24px;
      }
      .steps {
        width: 800px;
        .mixinFlex(space-between; flex-start);
        margin: 0 auto;
        .step {
          width: 160px;
          .mixinFlex(center; center);
          flex-direction: column;
          overflow: hidden;
          .step-name {
            background-color: rgba(255, 255, 255, 0.4);
            height: 36px;
            padding: 0 14px;
            color: rgba(255, 255, 255, 0.7);
            font-size: 16px;
            font-weight: 700;
            line-height: 36px;
            border-radius: 18px;
            position: relative;
          }
          .spot {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.4);
            position: relative;
            i {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background-color: rgba(255, 255, 255, 0.7);
              position: absolute;
              top: 9px;
              left: 9px;
            }
          }
          .text {
            color: rgba(255, 255, 255, 0.7);
            text-align: center;
            .name {
              font-size: 16px;
              font-weight: bold;
              line-height: 20px;
              margin-top: 8px;
            }
            .time {
              font-size: 14px;
              line-height: 20px;
              margin-top: 2px;
            }
          }
          &.step-l {
            .step-name,
            .spot {
              &::before {
                content: "";
                width: 100px;
                border: 1px dashed rgba(255, 255, 255, 0.7);
                position: absolute;
                top: 17px;
                right: calc(100% + 2px);
              }
            }
          }
          &.step-r {
            .step-name,
            .spot {
              &::after {
                content: "";
                width: 100px;
                border: 1px dashed rgba(255, 255, 255, 0.7);
                position: absolute;
                top: 17px;
                left: calc(100% + 2px);
              }
            }
          }
          &.active {
            .step-name {
              background-color: #fff45c;
              color: var(--theme);
            }
            .spot {
              background-color: rgba(255, 244, 92, 0.4);
              i {
                background-color: #fff45c;
              }
            }
            .text {
              color: #fff45c;
            }
          }
          &.active-l {
            &.step-l {
              .step-name,
              .spot {
                &::before {
                  border-color: #fff45c;
                }
              }
            }
          }
          &.active-r {
            &.step-r {
              .step-name,
              .spot {
                &::after {
                  border-color: #fff45c;
                }
              }
            }
          }
        }
      }
    }
    .tabs {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 0 15px 0 rgba(148, 148, 148, 0.15);
      padding: 10px 24px 12px;
      margin-top: 24px;
      min-height: 600px;
      .competition-detail {
        h3 {
          font-size: 20px;
          font-weight: 600;
          line-height: 26px;
          color: #333333;
          margin-bottom: 12px;
        }
        p {
          font-size: 16px;
          line-height: 26px;
          color: #333333;
          margin-bottom: 12px;
          white-space: pre-wrap;
          strong {
            font-weight: 600;
          }
        }
      }
      .contest-list {
        .card {
          padding: 12px 0;
          .mixinFlex(space-between);
          border-bottom: 1px solid #f4f4f4;
          &:last-child {
            border-bottom: none;
          }
          .ranking-num {
            width: 100px;
            padding-right: 30px;
            .mixinFlex(center; center);
            flex-direction: column;
            .number {
              font-size: 24px;
              font-weight: 700;
              line-height: 35px;
              color: #999999;
              img {
                width: 39px;
                height: 51px;
              }
            }
            .score {
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              color: var(--theme);
              margin-top: 4px;
            }
          }
          .cover {
            .mixinImgWrap(258px; 145px);
            border-radius: 8px;
            overflow: hidden;
          }
          .detail {
            width: calc(100% - 282px);
            height: 145px;
            .mixinFlex(space-between; center);
            .text {
              width: calc(100% - 180px);
              height: 145px;
              h5 {
                font-size: 16px;
                .mixinEllipsis(28px);
                margin-bottom: 10px;
              }
              .intro {
                font-size: 14px;
                .mixinEllipsis(44px; 2);
                line-height: 22px;
                color: #666666;
                margin-bottom: 38px;
              }
              .bottom {
                font-size: 14px;
                color: #666666;
                margin-bottom: 0;
                line-height: 24px;
                .mixinFlex(flex-start; center);
                .user {
                  .mixinFlex(flex-start; center);
                  margin-right: 24px;
                  .avatar {
                    .mixinImgWrap(24px; 24px);
                    border-radius: 50%;
                    margin-right: 12px;
                  }
                }
              }
            }
            .btns {
              .mixinFlex(flex-end; center);
              .btn {
                padding: 3px 16px;
                height: 28px;
                line-height: 20px;
                font-size: 14px;
                color: #999;
                border: 1px solid #999;
                border-radius: 4px;
                cursor: pointer;
                transition: all 0.2s;
                &:hover {
                  color: var(--theme);
                  border-color: var(--theme);
                }
              }
            }
          }
        }
      }
      .works-list {
        .card {
          padding: 12px 0;
          .mixinFlex(space-between);
          border-bottom: 1px solid #f4f4f4;
          &:last-child {
            border-bottom: none;
          }
          .cover {
            .mixinImgWrap(258px; 145px);
            border-radius: 8px;
            overflow: hidden;
            cursor: pointer;
            .tag {
              position: absolute;
              top: 8px;
              left: 8px;
              border-radius: 4px;
              background-color: rgba(255, 255, 255, 0.8);
              font-size: 14px;
              line-height: 26px;
              height: 26px;
              padding: 0 12px;
              &.green {
                color: #22cf33;
              }
              &.blue {
                color: #377dff;
              }
              &.red {
                color: #e8673e;
              }
            }
          }
          .detail {
            width: calc(100% - 282px);
            height: 145px;
            .mixinFlex(space-between; center);
            .text {
              width: 690px;
              height: 145px;
              cursor: pointer;
              h5 {
                font-size: 16px;
                .mixinEllipsis(28px);
                margin-bottom: 10px;
              }
              .score {
                font-size: 14px;
                line-height: 22px;
                color: #666666;
                margin-bottom: 60px;
                span {
                  color: #22cf33;
                  font-weight: bold;
                }
              }
              .bottom {
                font-size: 14px;
                color: #666666;
                margin-bottom: 0;
                line-height: 24px;
                .mixinFlex(flex-start; center);
                .user {
                  .mixinFlex(flex-start; center);
                  margin-right: 24px;
                  .avatar {
                    .mixinImgWrap(24px; 24px);
                    border-radius: 50%;
                    margin-right: 12px;
                  }
                }
              }
            }
            .btns {
              .mixinFlex(flex-end; center);
              .btn {
                padding: 3px 16px;
                height: 28px;
                line-height: 20px;
                font-size: 14px;
                color: var(--theme);
                border: 1px solid var(--theme);
                border-radius: 4px;
                cursor: pointer;
                &.red {
                  color: #ff0000;
                  border-color: #ff0000;
                  margin-left: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
